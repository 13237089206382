import React from 'react';
import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/SEO';
import { theme } from '@styles';
import FooterSection from '@pageSections/common/footer';
import { IPrismicCampaign, PageContext } from '@appTypes';

import CallToActionSection from '@pageSections/common/callToAction';

const { colors, breakpoints } = theme;

const Privacy: React.FC<PageProps<IPrismicCampaign, PageContext>> = ({
  data: { prismicData, prismicFooterData, prismicCtaData },
  pageContext,
  location,
}) => {
  const data = prismicData!.edges[0].node.data;

  const { locale } = pageContext;

  const { meta_title, meta_description, meta_image, meta_url } = data;

  return (
    <>
      <Container>
        <SEO
          title={meta_title}
          description={meta_description}
          image={meta_image.url}
          url={meta_url}
          pathname={location.pathname}
          name='Hyperhuman'
          locale={locale}
        />
        <TitleContainer>Terms & Conditions</TitleContainer>
        <PrismicLegalContent
          dangerouslySetInnerHTML={{
            __html: data.campaign_main_text.html,
          }}
        />
      </Container>
      <CallToActionSection prismicCtaData={prismicCtaData} />
      <FooterSection prismicFooterData={prismicFooterData} />
    </>
  );
};

export default Privacy;

const TitleContainer = styled.div`
  font-family: 'Poppins';
  color: ${colors.outerSpace};
  font-size: 40px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.2px;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 60px;
`;

const PrismicLegalContent = styled.div`
  font-size: 14px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 700px;
    margin: 0 auto;
  }
`;

const Container = styled.div`
  margin-top: 34px;
  margin-bottom: 26px;
  padding: 0 16px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 44px;
    margin-bottom: 54px;
    padding: 0 24px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 54px;
    margin-bottom: 110px;
  }
`;

export const pageQuery = graphql`
  query CampaignPageQuery($locale: String!) {
    prismicData: allPrismicCampaign(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            meta_title
            meta_description
            meta_image {
              url
            }
            meta_url
            meta_keywords
            campaign_main_text {
              html
            }
          }
        }
      }
    }
    prismicFooterData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...FooterFieldsFragment
    }
    prismicCtaData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...CtaFieldsFragment
    }
  }
`;
